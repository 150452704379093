<template>
	<v-container>
		<v-row justify="center" style="min-height:calc(100vh - 520px)">
			<v-col lg=5 cols=12 align-self="center">
				<v-card>
					<v-form ref="form" v-model="valid" lazy-validation>
						<v-card-title>Кабинет эксперта</v-card-title>
						<v-card-text>
							<v-text-field outlined required type="email" v-model="form.email" :rules="rulleEmail" label="Введите адрес электронной почты"></v-text-field>
							<v-text-field outlined required type="password" v-model="form.password" :rules="rullePassword" label="Введите пароль"></v-text-field>
							
							<div class="d-flex justify-end">
								<v-btn color="primary" @click="login()" :disabled="!valid" :loading="btnLogin">Авторизоваться</v-btn>
							</div>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export default {
	data(){
		return {
			form: {},
			btnLogin: false,
			valid: false,

			rulleEmail: [
				v => !!v || 'Поле обязательно для заполнения',
				v => !!validateEmail(v) || 'Неверный формат электронной почты',
			],
			rullePassword: [
				v => !!v || 'Поле обязательно для заполнения',
				v => (v && v.length >= 3) || 'Длина пароля - более 3-х символов',
			],
		}
	},
	methods: {
		login() {
			this.$refs.form.validate()
			if ( !this.valid ) return;

			this.btnLogin = true
			this.$http.post("users/login", this.form)
				.then((response) => {
					if ( response.data.status ) {
						this.$toast.open("Вы успешно авторизовались в системе");
						this.$auth.setToken(
							response.data.token,
						);
						this.$router.push({name: 'StartPage'})
					}

					this.btnLogin = false
				}).catch((res) => {
					this.btnLogin = false
					this.$toast.error(res.data.error);
				});
		},
	}
}
</script>

<style>

</style>